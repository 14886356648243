import { I18N } from 'aurelia-i18n';
import { autoinject, observable } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { MyHttpClient, DocumentationAttachmentUpdateRequest } from 'utils/client';

@autoinject
export class AttachmentUpload
 {
  private attachment: DocumentationAttachmentUpdateRequest = {
    attachmentType: 'GENERIC_DOCUMENTATION',
    fileName: "",
    mimeType: "",
    data: "",
  };

  @observable
  fileList?: FileList;

  constructor(private i18n: I18N, private router: Router, private backend: MyHttpClient) {
  }

  async activate(params: { documentationId?: string }) {
    this.attachment.documentationId = parseInt(params.documentationId || "") || undefined;
  }

  fileListChanged() {
    if (!this.fileList?.length) {
        return;
    }
    let file = this.fileList[0];
    let fr = new FileReader();
    fr.onload = () => {
        if (typeof fr.result == "string") {
          let result = fr.result.replace(/^data:([^,]+),/, "");
          this.attachment.fileName = file.name;
          this.attachment.mimeType = file.type;
          this.attachment.data = result;
        }
    }
    fr.readAsDataURL(file);
  }

  async save(deleted: boolean) {
    if (!this.attachment.data) {
      return;
    }
    let tmp = {
      ...this.attachment,
      delete: deleted,
    };
    await this.backend.documentationAttachmentUpload(tmp);
    if (tmp.documentationId) {
      this.router.navigateToRoute("customers/edit", { id: "" + tmp.documentationId });
    } else {
      this.router.navigateToRoute("attachment/list");
    }
  }
}
