import { I18N } from 'aurelia-i18n';
import { ActorType, ActorEdenredUpdateRequest, MyHttpClient } from 'utils/client';
import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';

const actorTypes: ActorType[] = ["EDENRED_ADMIN", "CUSTOMER_SERVICE"];

@autoinject
export class ActorsEdenredEdit {
  private actor: ActorEdenredUpdateRequest = {
    username: "", password: "",
    actorType: 'CUSTOMER_SERVICE',
    businessId: "1057825-2",
    companyName: "Edenred Finland Oy",
  };
  private password2?: string;

  private actorTypeList: { id: ActorType, name: string }[] = actorTypes.map(at => ({ id: at, name: this.i18n.tr("ActorType." + at, {})}));

  constructor(private i18n: I18N, private router: Router, private backend: MyHttpClient) {
  }

  async activate(params: { id?: string, actorId?: string }) {
    let id = params.id || params.actorId;
    if (id) {
      this.actor = await this.backend.actorEdenredById({ id: parseInt(id), });
    }
  }

  async save(deleted: boolean) {
    if (this.actor.password && this.actor.password != this.password2) {
      return;
    }

    if (deleted && !confirm(this.i18n.tr("common.confirmDelete"))) {
      return;
    }

    let tmp = {
      ...this.actor,
      delete: deleted,
    };
    await this.backend.actorEdenredUpdate(tmp);
    this.router.navigateToRoute("actors/edenred/list");
  }
}
