
import { autoinject, bindable, bindingMode, computedFrom, observable } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';

let idGen = 0;

@autoinject
export class BelAuInputDateCustomElement {
  /* N.B. implicitly also @observable, see valueChanged() */
  @bindable({ defaultBindingMode: bindingMode.twoWay }) value?: Date;
  @bindable({ defaultBindingMode: bindingMode.toView }) required?: boolean;
  @bindable({ defaultBindingMode: bindingMode.toView }) disabled?: boolean;

  @bindable({ defaultBindingMode: bindingMode.toView }) min?: Date;
  @bindable({ defaultBindingMode: bindingMode.toView }) max?: Date;
  
  id = "input-date-" + ++idGen;
  @observable()
  valueLocal = "";

  constructor(private i18n: I18N) {
  }

  valueChanged() {
    this.valueLocal = this.value ? this.value.toISOString().substring(0, 10) : "";
  }

  valueLocalChanged() {
    this.value = this.valueLocal ? new Date(this.valueLocal) : undefined;
  }

  @computedFrom("min")
  get minAsIso() {
    return this.min ? this.min.toISOString().substring(0, 10) : "";
  }

  @computedFrom("max")
  get maxAsIso() {
    return this.max ? this.max.toISOString().substring(0, 10) : "";
  }
}
