import { I18N } from 'aurelia-i18n';
import { autoinject, LogManager } from "aurelia-framework";

interface Message {
  text: string;
  removeTimer: NodeJS.Timeout | number;
}

@autoinject
export class Notify {
  private spinnerCount = 0;
  private messages: Message[] = [];

  constructor(private i18n: I18N) {
  }

  info(text: string, args?: { [key: string]: any }) {
    text = this.i18n.tr(text, args);
    let message: Message = {
      text,
      removeTimer: setTimeout(() => this.messages = this.messages.filter(p => p !== message), 5000),
    };
    this.messages.push(message);
  }

  spinner(change: number) {
    this.spinnerCount += change;
  }
}
