import { Router } from 'aurelia-router';
import { MyHttpClient, HrSearchInternalRequest, HR } from 'utils/client';
import { FieldSpec } from 'components/bel-au-html-table/component';
import { autoinject } from 'aurelia-framework';

const request: HrSearchInternalRequest = {};
const sort = {
  sort: "createTime",
  reverse: true,
};
const filter = {};
let data: HR[] | undefined;

@autoinject
export class DataHrSearch {
  private fieldList: FieldSpec[] = [
    { key: "id", header: "#", type: "text", },
    { key: "createTime", header: "common.createTime", type: "date" },
    { key: "fileName", header: "common.fileName", type: "text" },
    { key: "companyNumber", header: "common.companyNumber", type: "text" },
  ];
  private request = request;
  private sort = sort;
  private data = data;
  private filter = filter;

  constructor(private router: Router, private backend: MyHttpClient) {
  }

  async search() {
    data = this.data = await this.backend.hrSearch(this.request);
  }

  row(value: HR) {
    this.router.navigateToRoute("data/hr/view", { id: "" + value.id });
  }
}
