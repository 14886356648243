import { autoinject, computedFrom } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { MyHttpClient, DocumentationAttachment } from 'utils/client';

@autoinject
export class AttachmentUpload
 {
  private attachment?: DocumentationAttachment;

  constructor(private router: Router, private backend: MyHttpClient) {
  }

  async activate(params: { id: string }) {
    this.attachment = await this.backend.documentationAttachmentById({ id: parseInt(params.id) });
  }

  @computedFrom()
  get downloadUrl() {
    return this.attachment ? this.backend.documentationAttachmentDownloadUrl({ id: this.attachment.id }) : undefined;
  }

  async remove() {
    if (!this.attachment) {
      return;
    }
    await this.backend.documentationAttachmentDelete({ id: this.attachment.id });
    if (this.attachment.documentationId) {
      this.router.navigateToRoute("customers/edit", { id: ""+this.attachment.documentationId });
    } else {
      this.router.navigateToRoute("attachment/list");
    }
  }
}
