import { FieldSpec } from 'components/bel-au-html-table/component';
import { PayrollSettings, MyHttpClient } from 'utils/client';
import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';

@autoinject
export class DataPayrollSettings {
  private edit = "";

  private req: PayrollSettings = {
    companyNumber: 0,
  };

  private fieldList: FieldSpec[] = [
    { key: "companyNumber", header: "common.companyNumber", type: "text", },
    { key: "payrollDeductionRateLunch", header: "payroll.payrollDeductionRateLunch", type: "number", minimumFractionDigits: 2 },
    { key: "payrollDeductionRateOther", header: "payroll.payrollDeductionRateOther", type: "number", minimumFractionDigits: 2 },
    { key: "payrollWageTypeLunch", header: "payroll.payrollWageTypeLunch", type: "text", },
    { key: "payrollWageTypeOther", header: "payroll.payrollWageTypeOther", type: "text", },
  ];

  private payrollSettingsList: PayrollSettings[] = [];

  constructor(private router: Router, private backend: MyHttpClient) {
  }

  async activate() {
    await this.refresh();
  }

  private async refresh() {
    this.payrollSettingsList = await this.backend.payrollSettingsList({});
  }

  select(req: PayrollSettings | undefined) {
    if (req) {
      this.req = { ...req };
    }
    this.edit = req ? "old" : "new";
  }

  async save(deleted: boolean) {
    let tmp = {
      ...this.req,
        delete: deleted,
    };
    await this.backend.payrollSettings(tmp);
    await this.refresh();
    this.edit = "";
  }

  async delete() {
    await this.save(true);
  }
}
