import { Router } from 'aurelia-router';
import { autoinject } from 'aurelia-framework';
import { MyHttpClient, Documentation } from 'utils/client';
import { FieldSpec } from 'components/bel-au-html-table/component';

@autoinject
export class CustomersList {
  private documentationList: Documentation[] = [];

  private fields: FieldSpec[] = [
    { header: "#", key: "id", type: "text" },
    { header: "documentation.customerType", key: "customerType", type: "enum", enum: "CustomerType" },
    { header: "documentation.customerNumber", key: "customerNumber", type: "text", },
    { header: "documentation.customerName", key: "customerName", type: "text", },
  ];

  constructor(private router: Router, private api: MyHttpClient) {
  }

  async activate() {
    this.documentationList = await this.api.documentationList({});
  }

  row(doc: Documentation) {
    return this.router.navigateToRoute("customers/edit", { id: ""+doc.id });
  }
}
