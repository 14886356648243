import { Notify } from 'utils/notify';
import { ActorPayrollCsUpdateRequest, ActorPayrollCsUpdateResponse, MyHttpClient } from 'utils/client';
import { I18N } from 'aurelia-i18n';
import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';

@autoinject
export class ActorsCsPayrollEdit {
  private actor: ActorPayrollCsUpdateRequest = {
    refreshPassword: false,
    businessId: "",
    companyName: "",
    companyNumberRestriction: 0,
    delete: false,
  };

  private response?: ActorPayrollCsUpdateResponse;

  constructor(private i18n: I18N, private notify: Notify, private router: Router, private backend: MyHttpClient) {
  }

  async activate(params: { id?: string, actorId?: string }) {
    let id = params.id || params.actorId;
    if (id) {
      let { actor, settings } = await this.backend.actorPayrollCsById({ id: parseInt(id), });
      this.actor = { ...actor, companyNumberRestriction: actor.companyNumberRestriction[0], ...settings, refreshPassword: false, delete: false };
    }
  }

  async save(deleted: boolean) {
    if (deleted && !confirm(this.i18n.tr("common.confirmDelete"))) {
      return;
    }
    let tmp = {
      ...this.actor,
      delete: deleted,
    };
    this.response = await this.backend.actorPayrollCsUpdate(tmp);
    this.notify.info("common.saved");
  }
}
