import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { FieldSpec } from 'components/bel-au-html-table/component';
import { MyHttpClient, DocumentationUpdateRequest, DocumentationAttachment, Actor, ActorAdminListResponse } from 'utils/client';

@autoinject
export class CustomersEdit
 {
  private doc: DocumentationUpdateRequest = {
    customerName: "",
    customerNumber: [],
    customerType: 'PBU',
    delete: false,
    hr: 'NONE',
    payroll: 'NONE',
  };

  private genericDocumentationList: DocumentationAttachment[] = [];

  private documentationList: DocumentationAttachment[] = [];
  private documentationFieldList: FieldSpec[] = [
    { header: "#", key: "id", type: "text" },
    { header: "documentation.fileName", key: "fileName", type: "text" },
    { header: "documentation.attachmentType", key: "attachmentType", type: "enum", enum: "AttachmentType" },
  ];

  private actorList: ActorAdminListResponse[] = []; 
  private actorFieldList: FieldSpec[] = [
    { header: "actor.username", key: "username", type: "text" },
    { header: "documentation.actorType", key: "actorType", type: "enum", enum: "ActorType" },
  ];

  private customerNumber = "";

  constructor(private router: Router, private api: MyHttpClient) {
  }

  async activate(params: { id?: string }) {
    let id = params.id;
    if (id) {
      let doc = await this.api.documentationById({ id: parseInt(id), });

      this.doc = { ...doc, delete: !!doc.deleteTime };
      this.customerNumber = this.doc.customerNumber.join(", ");

      this.actorList = await this.api.actorAdminList({ customerNumber: doc.customerNumber });
      this.documentationList = await this.api.documentationAttachmentList({ documentationId: doc.id });
    }

    this.genericDocumentationList = await this.api.documentationAttachmentList({ documentationId: undefined });
  }

  async save(deleted: boolean) {
    let customerNumber = this.customerNumber.split(/[,; ]+/).map(p => parseInt(p) || 0);
    if (!customerNumber.length) {
      alert("Missing customer number(s)");
      return;
    }
    let update = await this.api.documentationUpdate({ ...this.doc, customerNumber, delete: deleted });
    if (this.doc.id) {
      this.router.navigateToRoute("customers/list");
    } else {
      this.router.navigateToRoute("customers/edit", { id: "" + update.id })
    }
  }

  documentation(row: DocumentationAttachment) {
    this.router.navigateToRoute("attachment/edit", { id: ""+row.id });
  }

  actor(row: ActorAdminListResponse) {
    this.router.navigateToRoute("actors/bel/edit", { id: ""+row.id });
  }
}
