import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { MyHttpClient } from '../utils/client';

@autoinject
export class SessionLogout {
  constructor(private client: MyHttpClient, private router: Router) {
  }

  async activate() {
    await this.client.sessionLogout({});
    this.client.clearCredentials();
    this.router.navigateToRoute("index");
  }
}
