export default {
    ActorType: {
        ADMIN: "BEL administration",
        EDENRED_ADMIN: "Edenred administration",
        CUSTOMER_SERVICE: "Edenred customer service",
        EDENRED_PAYROLL_UPLOAD: "Standard: Payroll from Edenred",
        EDENRED_PAYROLL_DOWNLOAD: "Standard: Payroll to customer",
        EDENRED_HR_UPLOAD: "Standard: HR from customer",
        EDENRED_HR_DOWNLOAD: "Standard: HR to Edenred TOW",
        EDENRED_ARE_DOWNLOAD: "Custom: Payroll to ARE",
        EDENRED_ESPERI_UPLOAD: "Custom: HR from Esperi Care",
        EDENRED_INTRUM_UPLOAD: "Custom: HR from Intrum",
        EDENRED_LAHTI_ULDL: "Custom: Payroll to and HR from Lahden Kaupunki",
        EDENRED_PKL_DOWNLOAD: "Custom: Payroll to PKL",
        EDENRED_PWC_UPLOAD: "Custom: HR from PwC",
        EDENRED_PWC_DOWNLOAD: "Custom: Payroll to PwC",
        EDENRED_RAMBOLL_DOWNLOAD: "Custom: Payroll to Ramboll",
        EDENRED_SOFIGATE_DOWNLOAD: "Custom: Payroll to Sofigate",
        EDENRED_YIT_UPLOAD: "Custom: HR from YIT",
        EDENRED_YIT_DOWNLOAD: "Custom: Payroll to YIT",
    },
    AttachmentType: {
        GENERIC_DOCUMENTATION: "Generic integration document",
        BEL_CUSTOM_INTEGRATION: "BEL custom integration document",
        SPECIFICATION: "Specification from customer",
    },
    CustomerType: {
        PBU: "Pay by usage",
        PP: "Prepaid",
    },
    EventType: {
		LOGIN_FAILED: "Login failed",
        LOGIN_SUCCESS: "Login success",
        LOGOUT: "Logout",
		NEW_FILE: "File uploaded",
		OPEN_FILE_FAILED: "File open error",
        OPEN_FILE_SUCCESS: "File open success",
		SSH_SESSION_CLOSED: "SSH session closed",
		SSH_SESSION_CREATED: "New SSH session",
		SSH_SESSION_EVENT: "SSH event",
		SSH_SESSION_EXCEPTION: "SSH error",

		DATA_SUCCESS: "File upload success",
		DATA_ERROR: "File upload failed",

		PAYROLL_DOWNLOAD: "Payroll data downloaded",
    },
    IntegrationType: {
        NONE: "No integration",
        STANDARD: "Standard",
        CUSTOM: "Custom",
    },
    OrderLineStatus: {
        P: "Produced",
        N: "Not Produced",
    },
    OrderLineType: {
        L: "Benefit",
        N: "New card",
        W: "Renewal",
        c: "Issuer-related renewal",
        R: "Reissuing due to lost/stolen/damaged",
    },
    OrderType: {
        L: "Load",
        C: "Card",
        T: "Transaction",
    },
    ProductName: {
        "Ticket Duo": "Ticket Duo",
        "Ticket Transport": "Ticket Transport",
        "Ticket Massage": "Ticket Massage"
    },
    StatusCode: {
        Cancelled: "Cancelled",
        Entered: "Entered",
        Validated: "Validated",
        Produced: "Produced",
        Invoiced: "Invoiced",
        PartiallyProduced: "Partially produced",
        Paid: "Paid",
    },
    TypeOfEmployment: {
        F: "Full time",
        P: "Part time",
        T: "Temporary",
        I: "Inactive",
        H: "On hold",
        D: "Dropped"
    },
    WalletName: {
        TR: "Lunch",
        TMB: "Virike",
        TT: "Transport",
    },
    actor: {
        actorType: "Actor type",
        companyNumberRestriction: "Company number",
        username: "User name",
    },
    common: {
        both: "Both",
        city: "City",
        companyName: "Company name",
        companyNumber: "Company number",
        confirmDelete: "Delete this object?",
        createTime: "Created at",
        delete: "Delete",
        email: "Email",
        employeeNumber: "Employee number",
        employmentStartDate: "Employment start",
        employmentEndDate: "Employment end",
        false: "False",
        fileName: "Filename",
        firstName: "First name",
        fromDate: "From date",
        homeAddress: "Home address",
        lastName: "Last name",
        modifyTime: "Modified at",
        personalNumber: "Personal number",
        save: "Save",
        saved: "Information saved.",
        search: "Search",
        toDate: "To date",
        true: "True",
        uploaded: "File has been processed.",
        zip: "Zip",
    },
    documentation: {
        actorType: "Data format",
        attachmentType: "Attachment type",
        customerName: "Customer name",
        customerNumber: "Customer numbers(s), separated by comma",
        customerType: "Customer type",
        fileName: "File name",
        genericDocumentationId: "Generic documentation version with customer",
        genericDocumentationSent: "When generic documentation was sent",
        hr: "HR integration",
        payroll: "Payroll integration",
    },
    menu: {
        BEL: "Customers",
        EDENRED: "Edenred accounts",
        HR: "HR",
        PAYROLL: "Payroll",
        CUSTOM: "Custom integration",
        SYSTEM: "System",
    },
    payroll: {
        add: "Add new payroll-related company setting...",
        payrollDeductionRateLunch: "Payroll deduction % (Ticket Duo Lunch)",
        payrollDeductionRateRecreational: "Payroll deduction % (Ticket Duo Virike)",
        payrollDeductionRateOther: "Payroll deduction % (Other)",
        payrollWageTypeLunch: "Wage type (Ticket Duo Lunch)",
        payrollWageTypeRecreational: "Wage type (Ticket Duo Recreational)",
        payrollWageTypeOther: "Wage type (Other)",
    },
    server: {
        areFileNotRead: 'Could not parse ARE excel data: {{reason}}',
        invalidEmail: "Could not parse email address(es). Use comma to separate multiple addresses.",
        invalidPassword: "Old password is not valid, password not changed.",
        invalidPgpKey: "Could not parse PGP key. Valid key starts starts with: ----BEGIN PGP PRIVATE KEY BLOCK-----",
        invalidPublicKey: "Could not parse SFTP public key. Valid key starts with: ssh-rsa AAAA....",
        invalidUsernameOrPassword: "Username or password was not valid.",
        internalError: "Exception during processing: {{exception}}",
        passwordNotComplexEnough: "Password must be more complex (try using uppercase letters, lowercase letters, numbers and special characters)",
    },
};
