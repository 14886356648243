import en from './en';

export class LocaleBackend {
  type = "backend";
  init() {
    /* nothing */
  }
  read(language: string, namespace: string, callback: (error: string | undefined, data: {}) => void) {
    callback(undefined, en);
  }

}