// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes FadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes FadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.animate-fade {
  opacity: 0;
}
.animate-fade.au-enter-active {
  animation: FadeIn 0.3s;
}
.animate-fade.au-entered {
  opacity: 1;
}
.animate-fade.au-leave-active {
  animation: FadeOut 0.3s;
}
`, "",{"version":3,"sources":["webpack://./src/styles/animations.less"],"names":[],"mappings":"AAAA;EACI;IACI,UAAA;EACN;EAEE;IACI,UAAA;EAAN;AACF;AAGA;EACI;IACI,UAAA;EADN;EAIE;IACI,UAAA;EAFN;AACF;AAKA;EACI,UAAA;AAHJ;AAMA;EACI,sBAAA;AAJJ;AAOA;EACI,UAAA;AALJ;AAQA;EACI,uBAAA;AANJ","sourcesContent":["@keyframes FadeIn {\n    0% {\n        opacity: 0;\n    }\n\n    100% {\n        opacity: 1;\n    }\n}\n\n@keyframes FadeOut {\n    0% {\n        opacity: 1;\n    }\n\n    100% {\n        opacity: 0;\n    }\n}\n\n.animate-fade {\n    opacity: 0;\n}\n\n.animate-fade.au-enter-active {\n    animation: FadeIn 0.3s;\n}\n\n.animate-fade.au-entered {\n    opacity: 1;\n}\n\n.animate-fade.au-leave-active {\n    animation: FadeOut 0.3s;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
