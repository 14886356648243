import { Notify } from './../utils/notify';
import { autoinject } from 'aurelia-framework';
import { MyHttpClient } from '../utils/client';

@autoinject
export class SessionChangePassword {
  private oldPassword = "";
  private password = "";
  private password2 = "";

  constructor(private client: MyHttpClient, private notify: Notify) {
  }

  async changePassword() {
    if (this.password !== this.password2) {
      return;
    }

    await this.client.actorChangePassword({ oldPassword: this.oldPassword, password: this.password });
    this.oldPassword = this.password = this.password2 = "";
    this.notify.info("common.saved");
  }
}
