import { FieldSpec } from 'components/bel-au-html-table/component';
import { PayrollOrderItemV2, MyHttpClient } from 'utils/client';
import { autoinject, computedFrom } from 'aurelia-framework';

@autoinject
export class DataPayrollView {
  private id = 0;
  private fieldList: FieldSpec[] = [
    { key: "orderNumber", header: "TRI2 order number", type: "text" },
    { key: "orderLoadDate", header: "Load date", type: "date", format: "DD.MM.YYYY" },
    { key: "lastName", header: "Last name", type: "text" },
    { key: "firstName", header: "First name", type: "text" },
    { key: "employeeNumber", header: "Employee number", type: "text" },
    { key: "productName", header: "Product", type: "text" },
    { key: "walletName", header: "Wallet name", type: "enum", enum: "WalletName" },
    { key: "benefitAmount", header: "Load/Used amount", type: "number", minimumFractionDigits: 2 },
    { key: "deduction", header: "Deduction", type: "number", minimumFractionDigits: 2, },
    { key: "deductionRate", header: "Deduction %", type: "number", minimumFractionDigits: 2 },
    { key: "wageType", header: "Wage type", type: "text" },
    { key: "invoiceNumber", header: "Invoice number", type: "text" },
    { key: "invoiceDate", header: "Invoice date", type: "date", format: "DD.MM.YYYY" },
  ];
  private data?: PayrollOrderItemV2[];
  
  constructor(private backend: MyHttpClient) {
  }

  async activate(params: { id: string }) {
    this.id = parseInt(params.id);
    this.data = await this.backend.payrollJsonv2({ id: this.id })
  }

  @computedFrom("id")
  get excel() {
    return this.backend.payrollExcelUrl({ id: this.id });
  }

  @computedFrom("id")
  get json() {
    return this.backend.payrollJsonUrl({ id: this.id });
  }

  @computedFrom("id")
  get jsonv2() {
    return this.backend.payrollJsonv2Url({ id: this.id });
  }
}
