import { HRRow } from '../../utils/client';
import { FieldSpec } from 'components/bel-au-html-table/component';
import { autoinject, computedFrom } from 'aurelia-framework';
import { MyHttpClient } from 'utils/client';

@autoinject
export class DataHrView {
  private id = 0;
  private fieldList: FieldSpec[] = [
    { key: "firstName", header: "First name", type: "text" },
    { key: "lastName", header: "Last name", type: "text" },
    { key: "email", header: "Email", type: "text" },
    { key: "employeeNumber", header: "Employee number", type: "text" },
    { key: "typeOfEmployment", header: "Type of employment", type: "text" },
    { key: "employmentStartDate", header: "Benefit start", type: "date", format: "DD.MM.YYYY" },
    { key: "employmentEndDate", header: "Benefit end", type: "date", format: "DD.MM.YYYY" },
    { key: "lunchBenefit", header: "Lounas", type: "boolean", },
    { key: "recreationalBenefit", header: "Virike", type: "boolean", },
    { key: "transportBenefit", header: "Matka", type: "boolean", },
    { key: "massageBenefit", header: "Hieronta", type: "boolean", },
    { key: "dentalCareBenefit", header: "Hammas", type: "boolean", },
  ];
  private data: HRRow[] = [];

  constructor(private backend: MyHttpClient) {
  }

  async activate(params: { id: string }) {
    this.id = parseInt(params.id);
    this.data = await this.backend.hrView({ id: this.id })
  }

  @computedFrom("id")
  get csv() {
    return this.backend.hrCsvUrl({ id: this.id });
  }
}
