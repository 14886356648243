import { autoinject } from 'aurelia-framework';
import { MyHttpClient, EventLogWithActor } from 'utils/client';
import { FieldSpec } from 'components/bel-au-html-table/component';

@autoinject
export class EventLogBelList {
  private logList: EventLogWithActor[] = [];

  private fields: FieldSpec[] = [
    { header: "Time", key: "createTime", "type": "date" },
    { header: "Event type", key: "eventType", "type": "enum", "enum": "EventType" },
    { header: "Remote", key: "remoteAddress", "type": "text" },
    { header: "Session (WEB)", key: "webSessionId", "type": "text" },
    { header: "Session (SSH)", key: "sshSessionId", "type": "text" },
    { header: "User", key: "username", "type": "text" },
    { header: "Description", key: "description", "type": "json", },
  ];

  constructor(private backend: MyHttpClient) {
  }

  async activate() {
    this.logList = await this.backend.eventLogList({});
  }
}
