import { Router } from 'aurelia-router';
import { FieldSpec } from 'components/bel-au-html-table/component';
import { Payroll, PayrollSearchInternalRequest, MyHttpClient } from 'utils/client';
import { autoinject } from 'aurelia-framework';

const request: PayrollSearchInternalRequest = {};
const sort = {
  sort: "createTime",
  reverse: true,
};
const filter = {};
let data: Payroll[] | undefined;

@autoinject
export class AdminPayrollSearch {
  private fieldList: FieldSpec[] = [
    { key: "createTime", header: "common.createTime", type: "date", format: "DD.MM.YYYY" },
    { key: "fileName", header: "common.fileName", type: "text" },
    { key: "companyNumber", header: "common.companyNumber", type: "text" },
    { key: "companyName", header: "common.companyName", type: "text" },
  ];
  private request = request;
  private sort = sort;
  private data = data;
  private filter = filter;
  
  constructor(private router: Router, private backend: MyHttpClient) {
  }

  async search() {
    data = this.data = await this.backend.payrollSearch(this.request);
  }

  row(value: Payroll) {
    this.router.navigateToRoute("data/payroll/view", { id: "" + value.id });
  }
}