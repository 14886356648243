import { Router } from 'aurelia-router';
import { autoinject } from 'aurelia-framework';
import { MyHttpClient, Documentation, DocumentationAttachment } from 'utils/client';
import { FieldSpec } from 'components/bel-au-html-table/component';

@autoinject
export class AttachmentList {
  private documentationList: DocumentationAttachment[] = [];

  private fields: FieldSpec[] = [
    { header: "#", key: "id", type: "text" },
    { header: "documentation.fileName", key: "fileName", type: "text" },
    { header: "documentation.attachmentType", key: "attachmentType", type: "enum", enum: "AttachmentType" },
  ];

  constructor(private router: Router, private api: MyHttpClient) {
  }

  async activate() {
    this.documentationList = await this.api.documentationAttachmentList({});
  }

  row(doc: Documentation) {
    return this.router.navigateToRoute("attachment/edit", { id: ""+doc.id });
  }
}
