import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { MyHttpClient } from '../utils/client';

@autoinject
export class SessionLogin {
  username = "";
  password = "";

  constructor(private client: MyHttpClient, private router: Router) {
  }

  async login() {
    let session = await this.client.sessionLoginByUsername({ username: this.username, password: this.password });
    this.client.setCredentials(session);
    this.router.navigateToRoute("index");
  }
}
