import { ActorEdenredListResponse, MyHttpClient } from 'utils/client';
import { Router } from 'aurelia-router';
import { autoinject } from 'aurelia-framework';
import { FieldSpec } from 'components/bel-au-html-table/component';

@autoinject
export class ActorsEdenredList {
  private actorList: ActorEdenredListResponse[] = [];

  private fields: FieldSpec[] = [
    { header: "Username", key: "username", type: "text" },
    { header: "Type", key: "actorType", type: "enum", enum: "ActorType" },
    { header: "Name", key: "companyName", type: "text" },
    { header: "Business ID", key: "businessId", type: "text" },
    { header: "SFTP?", key: "canSftpLogin", type: "boolean" },
    { header: "Web UI?", key: "canWebLogin", type: "boolean" },
    { header: "Created by", key: "createActor", type: "number", },
    { header: "Created at", key: "createTime", type: "date", },
    { header: "Modified by", key: "modifyActor", type: "number", },
    { header: "Modifed at", key: "modifyTime", type: "date", },
  ];

  constructor(private router: Router, private backend: MyHttpClient) {
  }

  async activate() {
    this.actorList = await this.backend.actorEdenredList({});
  }

  row(actor: ActorEdenredListResponse) {
    this.router.navigateToRoute("actors/edenred/edit", { id: "" + actor.id });
  }
}
