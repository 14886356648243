import { EventErrorList } from './../../utils/client';
import { MyHttpClient } from 'utils/client';
import { autoinject } from 'aurelia-framework';
import { FieldSpec } from 'components/bel-au-html-table/component';

@autoinject
export class EventLogCsList {
  private logList: EventErrorList[] = [];

  private fields: FieldSpec[] = [
    { header: "Time", key: "createTime", "type": "date" },
    { header: "Event type", key: "eventType", "type": "enum", "enum": "EventType" },
    { header: "Username", key: "username", "type": "text" },
    { header: "User type", key: "actorType", "type": "enum", "enum": "ActorType" },
    { header: "Description", key: "description", "type": "json", },
  ];

  constructor(private backend: MyHttpClient) {
  }

  async activate() {
    this.logList = await this.backend.eventLogErrorList({});
  }
}
