// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `bel-au-html-table div.caption {
  text-align: right;
  padding: 0 4px;
}
bel-au-html-table div.caption span.select {
  float: left;
}
bel-au-html-table div.caption span.available {
  color: bold;
}
bel-au-html-table div.caption a {
  margin: 0 0.25em;
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/components/bel-au-html-table/component.less"],"names":[],"mappings":"AAAA;EAEI,iBAAA;EACA,cAAA;AAAJ;AAHA;EAMQ,WAAA;AAAR;AANA;EAUQ,WAAA;AADR;AATA;EAcQ,gBAAA;EACA,eAAA;AAFR","sourcesContent":["bel-au-html-table {\n  div.caption {\n    text-align: right;\n    padding: 0 4px;\n\n    span.select {\n        float: left;\n    }\n\n    span.available {\n        color: bold;\n    }\n\n    a {\n        margin: 0 0.25em;\n        cursor: pointer;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
