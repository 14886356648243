import { autoinject, bindable, bindingMode, computedFrom } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';

@autoinject
export class BelAuHtmlSelectEnumCustomElement {
  @bindable({ defaultBindingMode: bindingMode.twoWay }) value?: string;
  @bindable({ defaultBindingMode: bindingMode.toView }) class?: string;
  @bindable({ defaultBindingMode: bindingMode.toView }) disabled?: boolean;
  @bindable({ defaultBindingMode: bindingMode.toView }) placeholder?: string;
  @bindable({ defaultBindingMode: bindingMode.toView }) required?: boolean;
  @bindable({ defaultBindingMode: bindingMode.toView }) size?: number;
  @bindable({ defaultBindingMode: bindingMode.oneTime }) enum?: string;
  @bindable({ defaultBindingMode: bindingMode.oneTime }) allowDeleted?: boolean;
  @bindable({ defaultBindingMode: bindingMode.oneTime }) sort?: string;

  constructor(private i18n: I18N) {
  }

  @computedFrom("i18n.i18next.language")
  get content() {
    if (!this.enum) {
      return;
    }
    // Hack, getDataByLanguage is not seen by TS
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    let enumTranslation: { [key: string]: string; } = { ...this.i18n.i18next.getDataByLanguage(this.i18n.getLocale()).translation[this.enum] };
    if (!enumTranslation) {
      console.warn(`No translations found for ${this.enum}`);
      return;
    }
    if (this.allowDeleted && this.value != null && !(this.value in enumTranslation)) {
      enumTranslation[this.value] = this.value;
    }
    let content = Object.keys(enumTranslation);
    if (this.sort == "key") {
      content.sort((a, b) => a.localeCompare(b));
    } else if (this.sort == "value") {
      content.sort((a, b) => enumTranslation[a].localeCompare(enumTranslation[b], 'fi'));
    }
    return content;
  }
}
