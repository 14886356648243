// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `select.text-gray option {
  color: #000;
}
`, "",{"version":3,"sources":["webpack://./src/components/bel-au-html-select/component.less"],"names":[],"mappings":"AAGA;EACC,WAAA;AAFD","sourcesContent":[".text-gray {\n}\n\nselect.text-gray option {\n\tcolor: #000;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
