import { MyHttpClient, PayrollOrderItem } from 'utils/client';
import { autoinject } from 'aurelia-framework';

@autoinject
export class DataPayrollManual {
  private companyNumber?: number;
  private companyName = "";
  private invoiceNumber?: string;
  private invoiceDate?: Date;

  private poi: Partial<PayrollOrderItem> = {
    accountOpened: new Date(),
    towSocialSecurityNumber: "010101-0101",
    towTypeOfEmployment: "F",
    tri2LoadAmount: 0,
    tri2OrderType: "T",
    tri2OrderLineStatus: "P",
    tri2OrderLineType: "L",
    tri2OrderInputDate: new Date(),
    tri2OrderLoadDate: new Date(),
    tri2ProductName: "Ticket Duo",
    tri2WalletName: "TR",
    tri2StatusCode: 'Produced',
  };

  constructor(private client: MyHttpClient) {
  }

  async manual() {
    await this.client.payrollManual({
      companyNumber: this.companyNumber || 0,
      companyName: this.companyName,
      invoiceNumber: this.invoiceNumber,
      invoiceDate: this.invoiceDate,
      poi: <PayrollOrderItem>this.poi,
    });
  }
}
