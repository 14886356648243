import { Router } from 'aurelia-router';
import { autoinject } from 'aurelia-framework';
import { MyHttpClient, Actor } from '../../utils/client';
import { FieldSpec } from 'components/bel-au-html-table/component';

@autoinject
export class ActorsCsHrList {
  private actorList: Actor[] = [];

  private fields: FieldSpec[] = [
    { header: "Username", key: "username", type: "text", },
    { header: "Company name", key: "companyName", type: "text" },
    { header: "Business id", key: "businessId", type: "text" },
    { header: "Company numbers", key: "companyNumberRestriction", type: "text" },
  ];

  constructor(private router: Router, private backend: MyHttpClient) {
  }

  async activate() {
    this.actorList = await this.backend.actorHrCsList({});
  }

  row(actor: Actor) {
    return this.router.navigateToRoute("actors/cs-hr/edit", { id: ""+actor.id });
  }
}