import { PLATFORM } from 'aurelia-pal';
import { autoinject, computedFrom } from 'aurelia-framework';
import { Router, RouterConfiguration, PipelineStep, NavigationInstruction, Next, Redirect } from 'aurelia-router';
import { MyHttpClient, Session } from 'utils/client';
import { Notify } from 'utils/notify';
import env from '../config/environment.json';

class AuthorizeStep implements PipelineStep {
  constructor(private client: MyHttpClient) {
  }

  run(navigationInstruction: NavigationInstruction, next: Next): Promise<any> {
    if (navigationInstruction.getAllInstructions().every(i => i.config.settings.permission(this.client.session))) {
      return next();
    }
    /* When entering session/login, wipe session first. */
    if (this.client.session) {
      this.client.clearCredentials();
    }
    return next.cancel(new Redirect("session/login"));
  }
}

@autoinject
export class App {
  private checkSessionTimer: any;
  public router?: Router;
  private menu = "SYSTEM";
  private test = env.test;

  constructor(private client: MyHttpClient, private notify: Notify) {
  }

  setMenu(menu: string) {
    this.menu = menu;
    let route = this.router?.routes.find(r => r.nav && r.settings.integration == menu && r.settings.permission(this.client.session));
    if (this.router && route && route.name) {
      this.router.navigateToRoute(route.name);
    }
  }

  @computedFrom("client.session")
  get menuList() {
    if (!this.router) {
      return [];
    }
    let tmp: any[] = [];
    for (let row of this.router.navigation) {
      if (!row.config.nav) {
        continue;
      }
      if (!row.settings.permission(this.client.session)) {
        continue;
      }
      if (!tmp.find(p => p.config.settings.integration == row.settings.integration)) {
        tmp.push(row);
      }
    }
    return tmp;
  }

  public configureRouter(config: RouterConfiguration, router: Router) {
    config.addAuthorizeStep(new AuthorizeStep(this.client));
    config.map([
      {
        route: [''],
        name: 'index',
        moduleId: PLATFORM.moduleName('./index'),
        title: 'Index',
        settings: {
          permission: (session?: Session) => session
        }
      },

      /* customers */
      {
        route: ['customers/edit'],
        name: 'customers/edit',
        moduleId: PLATFORM.moduleName('./customers/edit'),
        title: 'Edit customer',
        activationStrategy: 'replace',
        settings: {
          integration: "BEL",
          permission: (session?: Session) => session?.actorType === 'ADMIN',
        }
      },
      {
        route: ['customers/list'],
        name: 'customers/list',
        moduleId: PLATFORM.moduleName('./customers/list'),
        nav: true,
        title: 'Customers',
        settings: {
          integration: "BEL",
          permission: (session?: Session) => session?.actorType === 'ADMIN',
        }
      },
      {
        route: ['attachment/edit'],
        name: 'attachment/edit',
        moduleId: PLATFORM.moduleName('./attachment/edit'),
        settings: {
          integration: "BEL",
          permission: (session?: Session) => session?.actorType === 'ADMIN',
        }
      },
      {
        route: ['attachment/upload'],
        name: 'attachment/upload',
        moduleId: PLATFORM.moduleName('./attachment/upload'),
        settings: {
          integration: "BEL",
          permission: (session?: Session) => session?.actorType === 'ADMIN',
        }
      },
      {
        route: ['attachment/list'],
        name: 'attachment/list',
        moduleId: PLATFORM.moduleName('./attachment/list'),
        nav: true,
        title: 'Generic docs',
        settings: {
          integration: "BEL",
          permission: (session?: Session) => session?.actorType === 'ADMIN',
        }
      },

      /* actors */
      {
        route: ['actors/bel/edit'],
        name: 'actors/bel/edit',
        moduleId: PLATFORM.moduleName('./actors/bel/edit'),
        title: 'Edit user',
        settings: {
          integration: "BEL",
          permission: (session?: Session) => session?.actorType === 'ADMIN',
        }
      },
      {
        route: ['actors/bel/list'],
        name: 'actors/bel/list',
        moduleId: PLATFORM.moduleName('./actors/bel/list'),
        nav: true,
        title: 'List of users',
        settings: {
          integration: "BEL",
          permission: (session?: Session) => session?.actorType === 'ADMIN',
        }
      },
      {
        route: ['actors/edenred/edit'],
        name: 'actors/edenred/edit',
        moduleId: PLATFORM.moduleName('./actors/edenred/edit'),
        title: 'Edit user',
        settings: {
          integration: "EDENRED",
          permission: (session?: Session) => session?.actorType === 'EDENRED_ADMIN',
        }
      },
      {
        route: ['actors/edenred/list'],
        name: 'actors/edenred/list',
        moduleId: PLATFORM.moduleName('./actors/edenred/list'),
        nav: true,
        title: 'List of users',
        settings: {
          integration: "EDENRED",
          permission: (session?: Session) => session?.actorType === 'EDENRED_ADMIN',
        }
      },

      /* HR */
      {
        route: ['actors/cs-hr/edit'],
        name: 'actors/cs-hr/edit',
        moduleId: PLATFORM.moduleName('./actors/cs-hr/edit'),
        title: 'Edit account',
        settings: {
          integration: "HR",
          permission: (session?: Session) => session?.actorType === 'CUSTOMER_SERVICE'
        }
      },
      {
        route: ['actors/cs-hr/list'],
        name: 'actors/cs-hr/list',
        moduleId: PLATFORM.moduleName('./actors/cs-hr/list'),
        nav: true,
        title: 'Accounts',
        settings: {
          integration: "HR",
          permission: (session?: Session) => session?.actorType === 'CUSTOMER_SERVICE'
        }
      },

      /* Payroll */
      {
        route: ['actors/cs-payroll/edit'],
        name: 'actors/cs-payroll/edit',
        moduleId: PLATFORM.moduleName('./actors/cs-payroll/edit'),
        title: 'Edit account',
        settings: {
          integration: "PAYROLL",
          permission: (session?: Session) => session?.actorType === 'CUSTOMER_SERVICE'
        }
      },
      {
        route: ['actors/cs-payroll/list'],
        name: 'actors/cs-payroll/list',
        moduleId: PLATFORM.moduleName('actors/cs-payroll/list'),
        nav: true,
        title: 'Accounts',
        settings: {
          integration: "PAYROLL",
          permission: (session?: Session) => session?.actorType === 'CUSTOMER_SERVICE'
        }
      },
      {
        route: ['data/hr/search'],
        name: 'data/hr/search',
        moduleId: PLATFORM.moduleName('./data/hr/search'),
        nav: true,
        title: 'Data',
        settings: {
          integration: "HR",
          permission: (session?: Session) => session?.actorType === 'ADMIN' || session?.actorType === 'CUSTOMER_SERVICE',
        }
      },
      {
        route: ['data/hr/view'],
        name: 'data/hr/view',
        moduleId: PLATFORM.moduleName('./data/hr/view'),
        title: 'View',
        settings: {
          integration: "HR",
          permission: (session?: Session) => session?.actorType === 'ADMIN' || session?.actorType === 'CUSTOMER_SERVICE',
        }
      },
      {
        route: ['data/payroll/manual'],
        name: 'data/payroll/manual',
        moduleId: PLATFORM.moduleName('./data/payroll/manual'),
        nav: true,
        title: 'Manual entry',
        settings: {
          integration: "PAYROLL",
          permission: (session?: Session) => session?.actorType === 'ADMIN',
        }
      },
      {
        route: ['data/payroll/search'],
        name: 'data/payroll/search',
        moduleId: PLATFORM.moduleName('./data/payroll/search'),
        nav: true,
        title: 'Data',
        settings: {
          integration: "PAYROLL",
          permission: (session?: Session) => session?.actorType === 'ADMIN' || session?.actorType === 'CUSTOMER_SERVICE',
        }
      },
      {
        route: ['data/payroll/view'],
        name: 'data/payroll/view',
        moduleId: PLATFORM.moduleName('./data/payroll/view'),
        title: 'Payroll: view',
        settings: {
          integration: "PAYROLL",
          permission: (session?: Session) => session?.actorType === 'ADMIN' || session?.actorType === 'CUSTOMER_SERVICE',
        }
      },
      {
        route: ['data/payroll/settings'],
        name: 'data/payroll/settings',
        moduleId: PLATFORM.moduleName('./data/payroll/settings'),
        nav: true,
        title: 'Payroll: settings',
        settings: {
          integration: "PAYROLL",
          permission: (session?: Session) => session?.actorType === 'ADMIN',
        }
      },
      {
        route: ['event-log/bel/list'],
        name: 'event-log/bel/list',
        moduleId: PLATFORM.moduleName('./event-log/bel/list'),
        nav: true,
        title: 'All events',
        settings: {
          integration: "SYSTEM",
          permission: (session?: Session) => session?.actorType === 'ADMIN',
        }
      },
      {
        route: ['event-log/cs/list'],
        name: 'event-log/cs/list',
        moduleId: PLATFORM.moduleName('./event-log/cs/list'),
        nav: true,
        title: 'Data transfer events',
        settings: {
          integration: "SYSTEM",
          permission: (session?: Session) => session?.actorType === 'CUSTOMER_SERVICE' || session?.actorType === 'ADMIN',
        }
      },
      {
        route: ['session/login'],
        name: 'session/login',
        moduleId: PLATFORM.moduleName('./session/login'),
        nav: false,
        title: 'Login',
        settings: {
          integration: "SYSTEM",
          permission: (session?: Session) => !session
        }
      },
      {
        route: ['session/change-password'],
        name: 'session/change-password',
        moduleId: PLATFORM.moduleName('./session/change-password'),
        nav: false,
        title: 'Change password',
        settings: {
          integration: "SYSTEM",
          permission: (session?: Session) => session
        }
      },
      {
        route: ['session/logout'],
        name: 'session/logout',
        moduleId: PLATFORM.moduleName('./session/logout'),
        nav: false,
        title: 'Logout',
        settings: {
          integration: "SYSTEM",
          permission: (session?: Session) => session
        }
      },
    ]);

    this.router = router;
  }

  public permission(row: NavigationInstruction, session: Session) {
    return row.config.settings.permission(session);
  }

  async activate() {
    setInterval(() => this.checkSession(), 60000);
    await this.checkSession();
  }

  async checkSession() {
    if (!this.client.session) {
      return;
    }
    try {
      let result = await this.client.sessionStatus({});

      // @ts-ignore this constant comes from DefinePlugin, typescript won't know about it.
      let abbreviatedSha: string = ABBREVIATED_SHA;
      if (result.abbreviatedSha && abbreviatedSha && !result.abbreviatedSha.startsWith("$") && result.abbreviatedSha !== abbreviatedSha) {
        /* Attempt to trigger a no-caches reload of app */
        console.log("Application expired", result.abbreviatedSha, "vs", abbreviatedSha);
        window.location.reload();
      }

      if (!result.ping) {
        this.client.clearCredentials();
        this.router?.ensureConfigured().then(() => this.router?.navigateToRoute("session/login"));
      }
    } catch (e) {
    }
  }


  deactivate() {
    clearInterval(this.checkSessionTimer);
  }
}
