import { LocaleBackend } from './locales/locale-backend';
import {Aurelia} from 'aurelia-framework';
import environment from '../config/environment.json';
import {PLATFORM} from 'aurelia-pal';
import { TCustomAttribute } from 'aurelia-i18n';

export function configure(aurelia: Aurelia) {
  aurelia.use
    .standardConfiguration()
    .feature(PLATFORM.moduleName('resources/index'));

  aurelia.use.plugin(PLATFORM.moduleName('aurelia-i18n'), (instance: any) => {
    let aliases = ['t', 'i18n'];
    TCustomAttribute.configureAliases(aliases);
    instance.i18next.use(new LocaleBackend());
    return instance.setup({
      interpolation: {
        escapeValue: false,
      },
      attributes: aliases,
      lng: "en",
      fallbackLng: 'en',
      debug: false
    });
  });
  
  aurelia.use.developmentLogging(environment.debug ? 'debug' : 'warn');

  aurelia.use.plugin(PLATFORM.moduleName('aurelia-animator-css'));

  aurelia.start().then(() => aurelia.setRoot(PLATFORM.moduleName('app')));
}
